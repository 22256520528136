@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700;900&family=Fredericka+the+Great&family=Playfair+Display+SC:ital,wght@0,700;1,400&display=swap');

body {
  background-color: #f9faf5;
}
.results .album {
  background-color: #f9faf5;
}
body.preview {
  background: #333;
}

.merge > div {
  width: 100%;
}

p, span, h1, h2, h3 {
  font-family: Jost;
  color: #6c757d;
  /* font-family: 'Abril Fatface', serif; */
  /* font-family: 'Fredericka the Great'; */
}

.hidden {
  display: none !important;
}

.results-text {
 text-align: center;
}

.main-image {
  width: 450px;
  margin-bottom: 30px;
  margin-top: 80px;
}

.display-option,
.preview-title {
  display: none;
}
.display-option.merge,
.preview-title.merge {
  display: block;
}

.container-upgrade-wall {
  display: none;
}
.upgrade-wall {
  display: block;
  height: 100vh;
  position: fixed;
  background: #000;
  z-index: 9999;
  width: 61vw;
  text-align: center;
  padding-top: 40vh;
  opacity: 1;
}
.upgrade-wall a {
  color: #6c757d;
  text-decoration: none;
}

.left-col h2 {
  text-align: right;
  margin-bottom: 20px;
  font-family: 'Fredericka the Great';
}
.left-col span {
  font-size: 28px;
  display: block;
  padding-top: 10px;
}
.right-col h2 {
  text-align: left;
  margin-bottom: 20px;
  font-family: 'Fredericka the Great';
}

.left-col .form-control {
  text-align:right;
}

.card-placeholder {
  width: 100%;
}
.row .blank-row {
  opacity: 0.4 !important;
}
.loaded .blank-row.first-blank-row {
  opacity: 0.9 !important;
}
.loaded .blank-row .inline-loader {
  display: none;
}
.loaded .blank-row.first-blank-row .inline-loader {
  display: block;
}
.results-panel {
  display: none;
}
.crawl-complete .results-panel {
  display: block;
}
.crawl-complete .loading-panel {
  display: none;
}
.time-warning {
  float: left;
}
.results-data {
  font-family: Jost;
  font-size: 15px;
}

.loading.crawl-complete .loading-panel {
  display: block;
  visibility: visible;
}

.loading .results-panel {
  display: none;
}

.results-panel {
  display: none;
  margin-bottom: 25px;
}

.loading h1.results,
.loading .meta.small,
.loading .inline-loader {
  visibility: hidden;
}

.home > section{
  height: 100vh;
}

footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

footer p {
  margin-bottom: .25rem;
}

.compare-sites-submit {
  width: 100%;
  margin-top: 15px;
  font-size: 20px;
  padding: 10px 70px;
  letter-spacing: 2px;
}
.form-control {
  font-size: 20px !important;
  /* width: 290px; */
  background: none;
  border: 1px solid;
  border-radius: 0;
  padding: 5px 10px;
  width: 100%;
  border-color:#d0d0d0;
  margin-top: 15px;
}

.bi-x-circle,
.bi-check-circle {
  width: 30px;
  height: 30px;
  color: green;
  margin-top: -3px;
}
h1,
p {
  color: #666 !important;
}
.preview-page {
  padding-top: 50px;
}
.results-changes-found.fail,
.bi-x-circle {
  color: #d9534f;
  text-decoration: none;
}
.back-btn {
  color: #333;
  margin-bottom: 10px;
  display: block;
}
.loading .back-btn {
  visibility: hidden;
}
.title-pass {
  font-size: 18px;
  color: #008000;
  font-weight: bold;
}
.title-fail {
  font-size: 25px;
  color: #d9534f;
  font-weight: bold;
}
.info-panel .results-changes-found.fail {
  color: #d9534f;
  text-decoration: none;
  text-align: left;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.results-changes-found {
  display: block;
  width: 100%;
  text-align: right;
  padding-right: 8px;
}
/* .info-panel .results-changes-found {
  display: inline;
} */
.info-panel .btn-group {
  width: 100%;
  display: block;
}
.info-panel h1 {
  font-size: 35px;
}
.info-panel .btn-group span {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  word-wrap:break-word;
}
.form-control {
  font-size: 30px;
}
.results-changes-found.passed {
  color: green;
  font-size: 30px;
  width: 100%;
  /* display: block; */
  display: inline;
  text-align: left;
  margin-bottom: 12px;
}
.share-btn,
.rescan-btn {
  margin-top: 28px;
}
.share-btn {
  background: green;
  color: #fff;
  border-color: green;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 15px !important;
  border-radius: 0;
}

button.ui-option {
  background: none;
  color: rgba(255, 255, 255, 0.5);
  border: 0;
}
button.ui-option.active,
button.ui-option:hover {
  color: rgba(255, 255, 255, 1);
}

.home .results-changes-found.passed {
  font-size: 16px;
}

.results-changes-found.passed {
  font-size: 18px;
}

.control-panel-split {
  border-color: #fff;
  display: block;
  margin-bottom: 25px;
}
.results-link-site {
  float: left;
  margin-top: 5px;
}
.results-link-open {
  float: left;
  font-size: 10px;
  margin-left: 0;
  margin-right: 5px;
  color: #333;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 1px;
}
.view-changes {
  font-size: 13px;
  text-decoration: none;
  font-weight: 600;
  color: #333;
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.info-panel {
  opacity: 0.9;
  border-left: 1px solid #56575B;
}
.info-panel:hover {
  opacity: 1;
}
.card {
  transition: transform .3s;
}
.card:hover{
  transform: scale(1.03);
}
.preview .card {
  border-radius: 0;
}

.card-text {
  font-size: 12px;
}
.card-text-description {
  font-weight: 200;
  font-size: 15px;
  height: 145px;
  text-align: left;
}
.card-body {
  margin-top: 20px;
  border-top: 5px solid #f3f3f3;
}

.results-link-site {
  font-size: 20px;
  color: #333;
  width: 100%;
  text-decoration: none;
  margin-top: 0;
  margin-bottom: 20px;
  line-height: 30px;
  text-align: left;
}
.results h1.results {
  margin-bottom: 20px;
}
strong.meta {
  font-family: 'Jost';
  font-weight: normal;
  font-size: 15px;
  display: block;
  color: #333;
  width: 100%;
  text-decoration: none;
  margin-top: 5px;
  margin-bottom: 25px;
  line-height: 30px;
  text-align: right;
}
.inline-loader {
  position: absolute;
  margin-top: 15px;
  margin-left: 15px;
  opacity: 0.5 !important;
}

small.meta {
  font-size: 20px;
  font-weight: normal;
  margin-top: 1px;  
  margin-bottom: 10px;
}

.action-icons {
  display: block;
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.loading-robot {
  margin-bottom: 55px !important;
  margin-top: 100px !important;  
}
div.spacer {
  float: left;
  margin: 50px 0px 100px 0px;
}
h1.results,
.lead {
  font-family: Fredericka the Great;
  font-size: 30px;
  margin-bottom: 55px;
  line-height: 1.3;
  color: #333 !important;
  letter-spacing: 0.9px;
}
#crawl-wall {
  margin-top: 200px;
  margin-bottom: 75px;
  float: left;
}
h2.crawl-wall {
  text-align: left;
  font-family: Fredericka the Great;
  color: #333;
  font-size: 25px;  
  margin-top: 100px;
  margin-bottom: 10px;
}
.home .lead {
  color: #000 !important;
}
.results .results {
  font-family: Jost;
  letter-spacing: 0px;
}
p.results-loading {
  font-family: Fredericka the Great;
  font-size: 31px;
  line-height: 1.2;
  text-align: center;
}
.btn-primary {
  background: none;
  color: #6c757d;
  border-color: #6c757d;
  width: auto;
  margin-top: 50px;
  border-radius: 0;
  /* font-family: 'Abril Fatface', serif; */
  font-weight: 600;
}

/* Homepage web form */

.consent-label {
  color: #6c757d;
  margin-left: 5px;
}

.form-info {
  width: 700px;
  margin: 0 auto;
  text-align: left;
  margin-top: 20px;
  font-family: 'Jost';  
}


.flex-form input[type="submit"] {
  /* font-family: 'Fredericka the Great'; */
  letter-spacing: 1px;
  font-size: 19px;
  background: #005B5B;
  border: 1px solid #005B5B;
  color: #fff;
  padding: 0 30px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.3);
}

.flex-form input[type="submit"]:hover {
  background: #3B8B8B;
  border: 1px solid #3B8B8B;
}

.flex-form {
  display: -webkit-box;
  display: flex;
  z-index: 10;
  position: relative;
  width: 700px;
}

.flex-form > * {
  border: 0;
  padding: 0 0 0 15px;
  background: #fff;
  line-height: 50px;
  font-size: 1rem;
  border-radius: 0;
  outline: 0;
  -webkit-appearance: none;
}

input[type="search"] {
  flex-basis: 700px;
}

#error-messages {
  display: block;
  margin-top: 20px;
}

#error-messages span {
  display: block;
  color: #6c757d;
  width: max-content;
}

#error-messages span::before {
  content: ' ';
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMC8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvVFIvMjAwMS9SRUMtU1ZHLTIwMDEwOTA0L0RURC9zdmcxMC5kdGQnPjxzdmcgaGVpZ2h0PSIzMiIgc3R5bGU9Im92ZXJmbG93OnZpc2libGU7ZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzMiAzMiIgdmlld0JveD0iMCAwIDMyIDMyIiB3aWR0aD0iMzIiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxnIGlkPSJFcnJvcl8xXyI+PGcgaWQ9IkVycm9yIj48Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiBpZD0iQkciIHI9IjE2IiBzdHlsZT0iZmlsbDojRDcyODI4OyIvPjxwYXRoIGQ9Ik0xNC41LDI1aDN2LTNoLTNWMjV6IE0xNC41LDZ2MTNoM1Y2SDE0LjV6IiBpZD0iRXhjbGFtYXRvcnlfeDVGX1NpZ24iIHN0eWxlPSJmaWxsOiNFNkU2RTY7Ii8+PC9nPjwvZz48L2c+PC9zdmc+);
  display: block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}

.form-container form {
  margin: 0 auto;
}
input.live-url,
input.stage-url {
  border-right: 5px solid #f3f3f3;
  padding-right: 10px;
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.3);
}

.control-panel {
  list-style: none;
  float: right;
  padding-left: 0;
  margin-bottom: 10px;
}
.control-panel-vertical {
  position: fixed;
  left: 0;
  background: #292c31;
  height: 100vh;
  margin-left: 0;
  top: 0;
  border-right: 1px solid #56575B;
}
.control-panel li {
  transition: all 0.3s;
  width: 60px;
  height: 50px;
  /* background: #005B5B; */
  color: #fff;
  text-align: center;
  font-size: 20px;
  /* padding: 0px 10px 0px 10px; */
}
.control-panel-horizontal li {
  width: 40px;
  height: 40px;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.display-option img {
  width: 100%;
}
.preview-title {
  float: left;
  margin-bottom: 45px;
  font-size: 34px;
}
.progress {
  height: 30px;
}

.info-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 300px;
  background: #292c31;
  padding: 30px;
  padding-top: 50px;
}
.info-panel h1, 
.info-panel p,
.btn-group span {
  color: #f7f7f7 !important;
}
.info-panel .rescan-btn {
  color: #fff;
  border-color: #fff;  
}
li.back-btn {
  background: #24262b;
  height: 64px;
  border-bottom: 1px solid #56575B;
  padding-top: 7px;
  font-size: 25px;
}
li.back-btn svg {
  color: #6c757d;
}
li.back-btn:hover svg {
  color: #fff;
}
.load-more {
  margin-top: 50px;
}
.load-more a {
  color: #005B5B;
  font-size: 35px;
  opacity: 0.5;
}
.load-more a:hover {
  opacity: 1;
}
.control-panel li:hover a {
  color: rgba(255, 255, 255, 0.8);
}
.control-panel-horizontal li {
  float: left;
  margin-bottom: 15px;
}
.control-panel-vertical li {
  margin-bottom: 10px;
}
.control-panel li a {
  color: rgba(255, 255, 255, 0.5);
  float: left;
  width: 100%;
  height: 100%;
}
.control-panel-horizontal li a {
  color: rgba(255, 255, 255, 0.5);
}
.control-panel li.active a {
  color: rgba(255, 255, 255, 1);
}
.control-panel li svg {
  margin-top: 13px;
}

#latest-results {
  margin-top: 200px;
}

@media all and (max-width:800px) {

  .flex-form {
    width: 100%;
  }

  input[type="search"] {
    flex-basis: 100%;
  }

  .flex-form > * {
    font-size: 0.9rem;
  }

}

@media all and (max-width:360px) {
  
  .flex-form {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  input[type="search"] {
    flex-basis: 0;
  }

  label {
    display: none;
  }
}